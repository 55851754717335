import { NavigationRoutes } from 'common/routes';
import { isSupportedVersion } from 'common/utils/string';
import { BottomNavigationBar } from 'components/widgets/footer';
import ResponsiveAppActionComponent from 'components/widgets/layout/actions';
import { AppLayoutActionsContext } from 'components/widgets/layout/actions/context';
import { AppActionType } from 'components/widgets/layout/actions/types';
import useDetectDevice, {
  getKurosimAppDeviceInfo,
} from 'hooks/use-detect-device';
import { useStateIfMounted } from 'hooks/use-effect-derivatives';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import React from 'react';

interface Props {
  tab: NavigationRoutes;
}

export default function KurosimBottomNavigationBar(props: Props) {
  const { tab } = props;
  const { isKurosimApp } = useDetectDevice();

  const navigations = React.useContext(AppLayoutActionsContext).filter(
    (action) => action.type === AppActionType.Navigation,
  );

  const [mobileDevice] = useStateIfMounted({
    initializer: getKurosimAppDeviceInfo,
  });

  const isMobile = useIsSmallScreen();

  // when desktop view
  if (!isMobile) return null;

  if (isKurosimApp && typeof mobileDevice === 'undefined') {
    return null;
  }

  // when app is compatible, use native bottom navigation dan hide web bottom navigation
  if (
    isKurosimApp &&
    isMobile &&
    isSupportedVersion('1.0.3', mobileDevice?.version)
  ) {
    return null;
  }

  return (
    <BottomNavigationBar<NavigationRoutes> value={tab}>
      {navigations.map((action) => (
        <ResponsiveAppActionComponent {...action} key={action.key} />
      ))}
    </BottomNavigationBar>
  );
}
