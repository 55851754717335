import React from 'react';

import { useKurosimSiteUrl } from './use-kurosim-navigation';
import { useExternalSiteNavigation } from './use-kurosim-navigation/navigator';

export function useNavigateSite() {
  const siteUrl = useKurosimSiteUrl();
  const __navigateNative = useExternalSiteNavigation();
  const navigateSite = React.useCallback(
    (route: string) => {
      const site = siteUrl(route);
      return __navigateNative(site);
    },
    [__navigateNative, siteUrl],
  );

  return navigateSite;
}
