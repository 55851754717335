import { SegmentedControl } from '@mantine/core';
import { ProductBrowseResponse } from 'api-hooks/product';
import { AssetPaths } from 'common/constants/assets';
import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import { capitalize } from 'common/utils/string';
import CloakContainer from 'components/cloak-container';
import Separator from 'components/common/separator';
import { AppHeader } from 'components/widgets/header';
import { AppLayoutHorizontalScrollArea } from 'components/widgets/layout/app';
import useDragToScroll from 'hooks/use-drag-to-scroll';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { EmptyView } from 'modules/status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { GuidesOfTheDay } from './banners';
import {
  CountryCardList,
  CountryList,
  RegionList,
} from './components/area-list';
import { LabelledSection } from '../components/section';

enum BrowseScreenBodyTab {
  CountryPlans = 'home:country_plans',
  RegionPlans = 'home:region_plans',
}

interface BrowseScreenBodyProps {
  data: ProductBrowseResponse;
}

interface BrowseScreenBodyEmptyViewProps {
  tab: BrowseScreenBodyTab;
}

function BrowseScreenBodyEmptyView({ tab }: BrowseScreenBodyEmptyViewProps) {
  const { t } = useTranslation();
  const title = t('error:extra_currently_unavailable_title', {
    extra:
      tab === BrowseScreenBodyTab.CountryPlans
        ? t('home:country_plans')
        : t('home:region_plans'),
  });
  return (
    <EmptyView
      imageSource={AssetPaths.Empty}
      title={title}
      description={t('error:extra_currently_unavailable_desc', {
        extra: t(
          capitalize(
            tab === BrowseScreenBodyTab.CountryPlans
              ? t('home:country_plans')
              : t('home:region_plans').toLowerCase(),
            {
              firstWordOnly: true,
            },
          ),
        ),
      })}
    />
  );
}

export default function BrowseScreenBody(props: BrowseScreenBodyProps) {
  const { t } = useTranslation();
  const {
    data: { countries, regionals, populars },
  } = props;
  const [tab, setTab] = React.useState(BrowseScreenBodyTab.CountryPlans);
  const { push } = useKurosimNavigation();
  const { ref } = useDragToScroll();

  return (
    <>
      <GuidesOfTheDay />
      <div className={structuralStyles.padding({ horizontal: 16 })}>
        <AppHeader.SearchBar
          disabled
          placeholder={t('home:header_search_placeholder')}
          onClick={() => {
            push(NavigationRoutes.Search, { query: {} });
          }}
        />
        <Separator gap={16} direction="vertical" />
        <SegmentedControl
          data={[
            {
              label: t(BrowseScreenBodyTab.CountryPlans),
              value: BrowseScreenBodyTab.CountryPlans,
            },
            {
              label: t(BrowseScreenBodyTab.RegionPlans),
              value: BrowseScreenBodyTab.RegionPlans,
            },
          ]}
          value={tab}
          onChange={setTab as any}
          classNames={{
            root: structuralStyles.fill({ width: true }),
          }}
        />
      </div>
      <CloakContainer isActive={tab === BrowseScreenBodyTab.CountryPlans}>
        {countries.length === 0 ? (
          <BrowseScreenBodyEmptyView tab={BrowseScreenBodyTab.CountryPlans} />
        ) : (
          <>
            <LabelledSection label={t('home:buy_popular_country_plans')}>
              <AppLayoutHorizontalScrollArea ref={ref as any}>
                <CountryCardList countries={populars} />
              </AppLayoutHorizontalScrollArea>
            </LabelledSection>
            <LabelledSection
              label={t('home:buy_country_plans', {
                count: Math.floor(countries.length / 10) * 10,
              })}
              customization={{
                root: {
                  style: CustomValue.combine({
                    paddingBottom: 16,
                  }),
                },
              }}
            >
              <CountryList countries={countries} />
            </LabelledSection>
          </>
        )}
      </CloakContainer>
      <CloakContainer isActive={tab === BrowseScreenBodyTab.RegionPlans}>
        {regionals.length === 0 ? (
          <BrowseScreenBodyEmptyView tab={BrowseScreenBodyTab.RegionPlans} />
        ) : (
          <RegionList regions={regionals} />
        )}
      </CloakContainer>
    </>
  );
}
