import { Carousel, type Embla } from '@mantine/carousel';
import { ArrowRightIcon } from 'common/assets';
import { AssetPaths } from 'common/constants/assets';
import colors from 'common/styles/colors';
import { tryFn } from 'common/utils/function';
import Separator from 'components/common/separator';
import Text, { MultilineText } from 'components/elements/text';
import VirtualizedComponent from 'components/virtualized-component';
import { useNavigateSite } from 'hooks/use-navigate-site';
import { useSingletonTimeout } from 'hooks/use-timer';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { CarouselStyles } from './components/styles.css';

interface BannerBlueprintProps {
  id: string;
  src: string;
  color: string;
  title: string;
  description: string;
  onClick?(): void;
  arrow: boolean;
}

function BannerBlueprint(props: BannerBlueprintProps) {
  const { src, color, title, description, onClick, arrow } = props;
  const id = `kurosim--banner--${useId()}`;
  return (
    <section aria-labelledby={id}>
      <div
        style={{
          position: 'relative',
          minHeight: '8rem',
          maxHeight: '15rem',
          borderRadius: 8,
          overflow: 'hidden',
          backgroundColor: color,
          transition: 'background-color 0.5s',
        }}
        onClick={onClick}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={src}
          alt={title}
          loading="eager"
          width="100%"
          height="100%"
          style={{
            objectFit: 'contain',
            position: 'absolute',
            opacity: 0.6,
            top: '0%',
            right: '15%',
            transform: 'scale(130%)',
            objectPosition: 'right bottom',
          }}
        />
        <div
          className={structuralStyles.padding({ all: 16 })}
          style={{
            zIndex: 999,
            height: '100%',
            position: 'relative',
          }}
        >
          <Text id={id} textVariant="h3">
            {title}
          </Text>
          <MultilineText textVariant="body1Regular">
            {description}
          </MultilineText>
          {!!arrow && (
            <>
              <Separator gap={16} direction="vertical" />
              <div style={{ position: 'absolute', right: 16, bottom: 16 }}>
                <ArrowRightIcon size={24} />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
export function GuidesOfTheDay() {
  const { t } = useTranslation();
  const [embla, setEmbla] = React.useState<Embla | null>(null);
  const { setTimeout, setInterval } = useSingletonTimeout();
  // const siteUrl = useKurosimSiteUrl();

  const navigateSite = useNavigateSite();

  const data: BannerBlueprintProps[] = React.useMemo(() => {
    const TALLY_KEY = 'guides-tally';
    const { data: tally = {} } = tryFn<Record<string, number>>(() => {
      const item = localStorage.getItem(TALLY_KEY);
      return item ? JSON.parse(item) : {};
    });
    function updateTally(key: string) {
      return function () {
        tally[key]++;
        setTimeout(() => {
          localStorage.setItem(TALLY_KEY, JSON.stringify(tally));
        }, 500);
      };
    }
    const original: BannerBlueprintProps[] = [
      {
        id: 'stay-connected',
        src: AssetPaths.MapGlobal,
        color: colors.lightCyan1,
        title: t('home:stay_connected_with_esim_title'),
        description: t('home:stay_connected_with_esim_description'),
        onClick: updateTally('stay-connected'),
        arrow: false,
      },
      {
        id: 'check-supported-device',
        src: AssetPaths.MapAsia,
        color: colors.greenYellow1,
        title: t('home:check_supported_device_title'),
        description: t('home:check_supported_device_description'),
        onClick: () => {
          navigateSite('/check-device-availability');
        },
        arrow: true,
      },
      {
        id: 'secure-connectivity',
        src: AssetPaths.MapEurope,
        color: colors.mauve1,
        title: t('home:secure_connectivity_with_esim_title'),
        description: t('home:secure_connectivity_with_esim_description'),
        onClick: updateTally('secure-connectivity'),
        arrow: false,
      },
    ];
    for (const props of original) {
      tally[props.id] = tally[props.id] ?? 0;
    }
    return original.sort((a, b) => tally[a.id] - tally[b.id]);
  }, [navigateSite, setTimeout, t]);

  React.useEffect(() => {
    if (!embla) return;
    const intervalId = setInterval(() => {
      embla.scrollNext();
    }, 15000);
    return () => clearInterval(intervalId as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embla]);

  return (
    <Carousel
      // height={200}
      // withIndicators
      classNames={CarouselStyles}
      getEmblaApi={setEmbla}
      loop
      slideGap={24}
      slideSize="min(calc(100% - 32px), 480px)"
      includeGapInSize={false}
    >
      {data.map((x) => (
        <Carousel.Slide key={x.id}>
          <VirtualizedComponent maxWidth={480} maxHeight={128} threshold={0}>
            <BannerBlueprint {...x} />
          </VirtualizedComponent>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
}
