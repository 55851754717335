import classNames from 'classnames';
import { AssetPaths } from 'common/constants/assets';
import * as React from 'react';
import structuralStyles from 'styles/layout.css';

export default function ColorfulBackgroundContainer(
  props: React.ComponentProps<'div'>,
) {
  return (
    <div
      {...props}
      className={classNames(props.className, structuralStyles.border())}
      style={{
        ...props.style,
        backgroundImage: `url(${AssetPaths.ColorfulBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
      }}
    />
  );
}

interface ColorfulBackgroundElementProps {
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  width?: number | string;
  height?: number | string;
  degrees?: number;
  style?: React.CSSProperties;
  src?: AssetPaths;
}

export function ColorfulBackgroundElement(
  props: ColorfulBackgroundElementProps,
) {
  return (
    /* eslint-disable-next-line @next/next/no-img-element */
    <img
      src={props.src ?? AssetPaths.GradientContent}
      loading="lazy"
      style={{
        position: 'absolute',
        top: props?.top,
        left: props?.left,
        bottom: props?.bottom,
        right: props?.right,
        width: props?.width,
        height: props?.height,
        transform: props?.degrees ? `rotate(${props.degrees}deg)` : undefined,
        ...props.style,
      }}
      alt=""
      role="presentation"
      aria-hidden="true"
    />
  );
}
