import { NavigationRoutes } from 'common/routes';
import { ColorfulBackgroundElement } from 'components/common/colorful-background';
import { AppLayout } from 'components/widgets/layout';
import { ResizeWhenScreen } from 'hooks/use-is-small-screen';
import BrowseScreen from 'modules/main/browse';
import KurosimHeader from 'modules/main/header';
import KurosimBottomNavigationBar from 'modules/main/navigation';
import React from 'react';

import { NextPageWithLayout } from './_app';

(BrowseScreen as NextPageWithLayout).getLayout = (page) => {
  return (
    <AppLayout
      Header={<KurosimHeader tab={NavigationRoutes.Store} />}
      Footer={<KurosimBottomNavigationBar tab={NavigationRoutes.Store} />}
    >
      <ColorfulBackgroundElement top={128} right={-160} />
      <ResizeWhenScreen
        mobile={<ColorfulBackgroundElement top={-160} left={-240} />}
        desktop={
          <ColorfulBackgroundElement top={-224} left={-144} degrees={120} />
        }
      />
      {page}
    </AppLayout>
  );
};

export default BrowseScreen;
