export class Referral {
  static readonly key = 'referral-key';
  static get() {
    const value = localStorage.getItem(Referral.key);
    return value;
  }
  static set(value: string) {
    localStorage.setItem(Referral.key, value);
  }
  static remove() {
    localStorage.removeItem(Referral.key);
  }
}
