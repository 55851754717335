import { useBrowseProducts } from 'api-hooks/product';
import { useAuth } from 'common/auth';
import { Referral } from 'common/repositories/referral';
import { NavigationRoutes } from 'common/routes';
import { UseQueryWrapperComponent } from 'components/common/fetch-wrapper-component';
import useKurosimNavigation, {
  StackNavigation,
} from 'hooks/use-kurosim-navigation';
import { useOnboardingOnFirstVisit } from 'modules/onboarding';
import React from 'react';

import BrowseScreenBody from './body';

export default function BrowseScreen() {
  const query = useBrowseProducts();
  useOnboardingOnFirstVisit();
  const [hasReferral, setHasReferral] = React.useState(false);
  const { push } = useKurosimNavigation();
  const { isAuthenticated, isLoading } = useAuth();

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    //reset
    const hasReferral = StackNavigation.get().find(
      (route) => route.pathname === NavigationRoutes.ReferralBind,
    );

    setHasReferral(!!hasReferral);
    StackNavigation.resetTo(NavigationRoutes.Store);
  }, []);

  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    const referralStored = Referral.get();
    if (isLoading || !isAuthenticated || !referralStored) return;
    if (hasReferral) {
      push(NavigationRoutes.ReferralBind, {
        query: {
          code: referralStored,
        },
      });
    }
  }, [hasReferral, isAuthenticated, isLoading, push]);

  return (
    <UseQueryWrapperComponent query={query}>
      {(props) => {
        return <BrowseScreenBody {...props} />;
      }}
    </UseQueryWrapperComponent>
  );
}
